<template>
  <div class="formInputState">
    <select 
      :id="id"
      :name="name"
      v-model="inputValue"
      v-bind:class="{error: !valid && touched && blurred, content: value != ''}"
      v-on:change="touched=true, checkValidation()"
      @blur="blurred = true, checkValidation()" >
      <option value="International">International</option>
      <optgroup label="United States">
        <option value="AK">AK</option>
        <option value="AL">AL</option>
        <option value="AR">AR</option>
        <option value="AS">AS</option>
        <option value="AZ">AZ</option>
        <option value="CA">CA</option>
        <option value="CO">CO</option>
        <option value="CT">CT</option>
        <option value="DC">DC</option>
        <option value="DE">DE</option>
        <option value="FL">FL</option>
        <option value="FM">FM</option>
        <option value="GA">GA</option>
        <option value="HI">HI</option>
        <option value="IA">IA</option>
        <option value="ID">ID</option>
        <option value="IL">IL</option>
        <option value="IN">IN</option>
        <option value="KS">KS</option>
        <option value="KY">KY</option>
        <option value="LA">LA</option>
        <option value="MA">MA</option>
        <option value="MD">MD</option>
        <option value="ME">ME</option>
        <option value="MI">MI</option>
        <option value="MN">MN</option>
        <option value="MO">MO</option>
        <option value="MS">MS</option>
        <option value="MT">MT</option>
        <option value="NC">NC</option>
        <option value="ND">ND</option>
        <option value="NE">NE</option>
        <option value="NH">NH</option>
        <option value="NJ">NJ</option>
        <option value="NM">NM</option>
        <option value="NV">NV</option>
        <option value="NY">NY</option>
        <option value="OH">OH</option>
        <option value="OK">OK</option>
        <option value="OR">OR</option>
        <option value="PA">PA</option>
        <option value="RI">RI</option>
        <option value="SC">SC</option>
        <option value="SD">SD</option>
        <option value="TN">TN</option>
        <option value="TX">TX</option>
        <option value="UT">UT</option>
        <option value="VA">VA</option>
        <option value="VT">VT</option>
        <option value="WA">WA</option>
        <option value="WI">WI</option>
        <option value="WV">WV</option>
        <option value="WY">WY</option>
      </optgroup>
      <optgroup label="US Territories">
        <option value="PR">Puerto Rico</option>
        <option value="VI">Virgin Island</option>
        <option value="MP">Northern Mariana Islands</option>
        <option value="GU">Guam</option>
        <option value="AS">American Samoa</option>
      </optgroup>
      <optgroup label="Canada">
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="NT">N.W.T.</option>
        <option value="ON">Ontario</option>
        <option value="QC">Quebec</option>
        <option value="PE">Prince Edward Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="YT">Yukon</option>
      </optgroup>
    </select>
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputState -->
</template>

<script>
export default {
  name: "FormInputState",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false
    }
  },
  props: {
    name: { type: String },
    value: { type: String },
    id: { type: String },
    label: { type: String },
    validation: { type: String, default: "none" }
  },
  mounted() {
    if(this.value != ""){
      this.checkValidation();
    }
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {        
        this.$emit('change', newValue);
        this.checkValidation();
      }
    }
  },
  methods: {
    checkValidation() {
      this.touched = true;
      this.blurred = true;
      if(this.value === "") {
        this.valid = false;
        this.$emit('notValid', this.valid, event);
      } else {
        this.valid = true;
        this.$emit('valid', this.valid, event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.formInputState {
  position: relative;
  width: 100%;


/* Change autofill color ;) */
:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus, 
:-webkit-autofill:active  {
      border: 1px solid rgba(0,0,0,0.3);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.3) inset;
      transition: background-color 5000s ease-in-out 0s;
}

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    optgroup {
      color: #000000;
    }
    optgroup option,
    option {
      color: #000000;
      font-size: 12px;
    }
  }

  .error {
    border: 1px solid red;
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size:12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }

}
</style>