<template>
  <div class="creditCardYear">
    <select 
      :id="id"
      :name="name"
      v-model="inputValue"
      v-bind:class="{error: !valid && touched && blurred, content: value != ''}"
      v-on:change="touched=true, checkValidation()"
      @blur="blurred = true, checkValidation()" >
        <option v-for="year in yearOptionsArray" :key="year.value" :value="year.value">{{year.text}}</option>
    </select>
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputState -->
</template>

<script>
export default {
  name: "CreditCardYear",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false,
      yearOptionsArray:[]
    }
  },
  props: {
    name: { type: String },
    value: { type: String },
    id: { type: String },
    label: { type: String },
    yearsBack: {type: Number, default: 2},
    yearsShown: {type: Number, default: 13},
    validation: { type: String, default: "none" }
  },
  mounted() {
    if(this.value != ""){
      this.checkValidation();
    }
  },
  created: function() {
    this.createYearOptions();
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {        
        this.$emit('change', newValue);
        this.checkValidation();
      }
    }
  },
  methods: {
    /* Creates the year options based on the current year */
    createYearOptions() {
      var yearsArray = [];
      var currentYear = new Date().getFullYear();
      var startYear = currentYear - this.yearsBack;
      for(var i=0; i < this.yearsShown; i++) {
        var yearOption = {value: (startYear+i).toString(), text: (startYear+i).toString()}
        yearsArray.push(yearOption);
      }
      this.yearOptionsArray = yearsArray;
    },
    checkValidation() {
      this.touched = true;
      this.blurred = true;
      if(this.value === "") {
        this.valid = false;
        this.$emit('notValid', this.valid, event);
      } else {
        this.valid = true;
        this.$emit('valid', this.valid, event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.creditCardYear {
  position: relative;
 width: 100%;


/* Change autofill color ;) */
:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus, 
:-webkit-autofill:active  {
      border: 1px solid rgba(0,0,0,0.3);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.3) inset;
      transition: background-color 5000s ease-in-out 0s;
}

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    optgroup {
      color: #000000;
    }
    optgroup option,
    option {
      color: #000000;
      font-size: 12px;
    }
  }

  .error {
    border: 1px solid red;
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size:12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }

}
</style>